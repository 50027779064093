import logo from '../img/logo-eric-red.png'

function Header() {
  return (
    <>
      <div className="header">
        <img className="logo" src={logo} alt="logo" />
      </div>
    </>
  )
}

export default Header
